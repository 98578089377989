var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-3",style:({
    backgroundColor: _vm.backgroundColor,
    boxShadow: '7px 7px 0px 1px #000000',
    border: '1px solid #000000',
    borderRadius: '20px',
    marginLeft: '5px !important',
  }),attrs:{"height":_vm.height,"width":_vm.width}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }