<template>
  <v-card
    :height="height"
    :width="width"
    class="mx-3"
    :style="{
      backgroundColor: backgroundColor,
      boxShadow: '7px 7px 0px 1px #000000',
      border: '1px solid #000000',
      borderRadius: '20px',
      marginLeft: '5px !important',
    }"
  >
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: "#fff",
    },
    width: {
      type: String,
      default: "auto",
    },
    height: {
      type: String,
      default: "auto",
    },
  },
};
</script>

<style></style>
